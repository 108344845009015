<template>
  <li :class="['accordion__item', { active: isOpen }]">
    <div
      :class="['accordion__item__header', { active: isOpen }]"
      @click="toggle()"
    >
      <slot name="header"></slot>
      <div :class="['accordion__item__toggle', 'accordion__item__toggle--closed', { 'accordion__item__toggle--opened': isOpen  }]">
        <div>
          <div class="accordion__item__toggle__horizontal"></div>
          <div class="accordion__item__toggle__vertical"></div>
        </div>
      </div>
    </div>
    <TransitionExpand :linear-margin="'1rem'">
      <div
        v-show="isOpen"
        :class="['accordion__item__content', { expand: props.expand }, 'rte']"
      >
        <slot name="content"></slot>
      </div>
    </TransitionExpand>
  </li>
</template>

<script setup>
import { ref } from 'vue';
import IconPlus from '../render/icons/IconPlus.vue';
import IconMinus from '../render/icons/IconMinus.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';

const props = defineProps({
  expand: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isOpen = ref(props.expand);

function toggle() {
  isOpen.value = !isOpen.value;
}
</script>
